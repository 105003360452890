import React from "react"
import styled from "styled-components"
import { PrimaryTitle } from "../../elements/Titles"

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2:first-child {
    margin: 6rem 0 3rem 0;
  }
`

const Paragraph = styled.p`
  font-size: 1.4rem;
  text-align: left;
  color: #2b3646;
  width: 50%;
  span {
    font-weight: bold;
  }
`

function ThankYou() {
  return (
    <StyledSection>
      <PrimaryTitle>
        Thank you for considering Custom <br />
        Alto Homes as your builder.
      </PrimaryTitle>
      <Paragraph>
        <span>In our words… </span> <br />
        <br />
        We both appreciate the natural beauty of Alto’s majestic views.
        <br />
        <br />
        Our homes are specifically designed for clients to optimize the best of
        both worlds - incredible views from the comfort of your luxurious home.
        <br />
        <br />
        Our goal is to “bring the outdoors in.” Our values are Honesty,
        Excellence, Accountability, Respect, and Partnership.
        <br />
        <br />
        We will build your home with diligence and attentiveness
      </Paragraph>
      <PrimaryTitle>
        We look forward to working with you!
        <br />- Mark and Bob
      </PrimaryTitle>
    </StyledSection>
  )
}

export default ThankYou

import React from "react"
import styled from "styled-components"
import img from "../../images/framingImg3.jpg"

const Bgcolor = styled.div`
  background: #2b3646;
`

const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2b3646;

  @media (max-width: 1020px) {
    justify-content: center;
    padding: 3rem 0;
  }
`

const ImageSide = styled.div`
  img {
    width: 600px;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1150px) {
    img {
      width: 400px;
    }
  }

  @media (max-width: 1020px) {
    display: none;
  }
`

const MarkBio = styled.p`
  font-size: 1.2rem;
  text-align: left;
  color: #f2f2f2;
  width: 50%;

  @media (max-width: 1020px) {
    width: 90%;
  }

  span {
    font-weight: bold;
  }
`

function MarkK() {
  return (
    <Bgcolor>
      <StyledSection className="wrapper">
        <ImageSide>
          <img src={img} alt="House in Framing Stage" />
        </ImageSide>
        <MarkBio>
          <span>Mark Kowalski</span> is a brand name in Las Cruces for very
          high-end cabinetry, carpentry, and homebuilding.
          <br />
          <br />
          He has over 35 years’ experience in the construction industry. Mark
          holds a bachelor’s degree from NMSU in Mechanical Engineering/Business
          Administration.
          <br />
          <br />
          Mark enjoys the serenity of the outdoors. Mark’s passion is building
          and creating; this company provides an ideal outlet.
          <br />
          <br />
          The two owners bring a unique blend of experience, skillsets and
          shared values resulting in an exceptional homebuilder.
        </MarkBio>
      </StyledSection>
    </Bgcolor>
  )
}

export default MarkK

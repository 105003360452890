import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"

import AboutShowcase from "../components/aboutpage/AboutShowcase"
import MarkK from "../components/aboutpage/MarkK"
import ThankYou from "../components/aboutpage/ThankYou"
import TreesBg from "../components/aboutpage/TreesBg"

function about() {
  return (
    <Layout>
      <SEO title="About Custom Alto Homes" />
      <AboutShowcase />
      <MarkK />
      <ThankYou />
      <TreesBg />
    </Layout>
  )
}

export default about

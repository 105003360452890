import React from "react"
import bgImg from "../../images/mountainBG.png"
import styled from "styled-components"

const BackgroundSection = styled.div`
  background-color: white;
  background-image: url('${bgImg}');
  background-position: bottom 0 right -20%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: -20rem 0 -12rem 0;
  position: relative;
  height: 500px;
  z-index: -1;
`

function TreesBg() {
  return (
    <BackgroundSection>
      <></>
    </BackgroundSection>
  )
}

export default TreesBg

import React from "react"
import styled from "styled-components"
import { PrimaryTitle } from "../../elements/Titles"

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 3rem 0;

  h2 {
    text-align: center;
  }
`

const Paragraph = styled.p`
  font-size: 1.2rem;
  text-align: left;
  align-self: flex-start;
  width: 55%;

  span {
    font-weight: bold;
  }

  @media (max-width: 755px) {
    width: 90%;
  }

  @media (max-width: 1020px) {
    align-self: center;
    padding: 1rem 0;
  }
`

function AboutShowcaseContent() {
  return (
    <StyledSection className="wrapper">
      <PrimaryTitle>
        New to Alto, with a proud history <br /> in real estate and
        construction.
      </PrimaryTitle>
      <Paragraph>
        <span>Bob Wilson</span>, co owns a residential real estate asset
        management company and construction company in El Paso.
        <br /> <br />
        He is a statistics professor in the Business School at the University of
        TX at El Paso. Before moving to El Paso in 2005, he worked on Wall
        Street for several years.
        <br />
        <br /> Bob holds a M.A. in Mathematics from UCLA and a B.S. in
        Mathematics from MIT.
        <br /> <br />
        Bob enjoys the natural beauty of the outdoors and building homes that
        allow homeowners to do the same.
      </Paragraph>
    </StyledSection>
  )
}

export default AboutShowcaseContent
